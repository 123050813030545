.main-plot {
  height: 100%;
  width: 100%;
}

.thumbnail-plot {
  height: 100%;
  width: 100%;
}

.thumbnail-btn {
  height: 100%;
  width: 100%;
  margin: 0;
}
.main-plot-custom {
  height: 100%;
  width: 100%;
  display:flex;
  flex-direction:row;
  justify-content: space-around;
}

.plot-inner {
  width: 100%;
  height: 100%;
}
.plot-outer {
  width: 90%;
  height: 100%;
}
.info-div {
 padding-left: 0px; 
 /* margin-right: 30px;  */
 width: 4%;
 vertical-align: top;
 margin-top:-15px;
}
